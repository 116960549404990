import React from 'react'
import { withRouter } from 'react-router'

import './styles.scss';

const LinkButton = withRouter((props) => {
    // source: https://stackoverflow.com/a/49439893/258794
    const {
        history,
        location,
        match,
        staticContext,
        to,
        onClick,
        ...rest
    } = props;
    return (
        <button
          {...rest}
          onClick={(event) => {
            onClick && onClick(event);
            history.push(to);
          }}
        />
    );
});

export default LinkButton;
