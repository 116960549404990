import React from 'react';

import LinkButton from '../LinkButton';

import './styles.scss';


const WelcomeModal = React.memo((props) => (
    <div className='WelcomeModal t-body'>
        <h1 className='WelcomeModal__title t-h1'>Roadsage</h1>
        <h2 className='WelcomeModal__subtitle t-h2'>
            How well do you know London’s streets?
        </h2>
        <p className='WelcomeModal__copy'>
            Click your way to within 100m of the given street in as few guesses as possible.
        </p>
        <h3 className='WelcomeModal__subtitle t-h3'>
            Choose play area:
        </h3>
        <ul className='WelcomeModal__area-list'>
            {Object.keys(props.availableDistrictGroups).map(dg => (
                <li
                    className='WelcomeModal__area-list__item'
                    key={dg}
                >
                    <label className='WelcomeModal__area-list__label'>
                        <input
                            className='WelcomeModal__area-list__input'
                            type='radio'
                            name='district'
                            value={dg}
                            defaultChecked={props.selectedDistrictGroup === dg}
                            onClick={() => props.onDistrictGroupSelect(dg)}
                        />
                        <span>{dg}</span>
                    </label>
                </li>
            ))}
        </ul>
        <p className='WelcomeModal__secondary-copy'>
            <strong>Let’s go!</strong>
        </p>
        <LinkButton className='WelcomeModal__button Button' to={props.linkTo}>Start</LinkButton>
    </div>
));

export default WelcomeModal;
