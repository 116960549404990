import React from 'react';

import './styles.scss';


const ScoreReviewModal = React.memo((props) => (
    <div className='ScoreReviewModal'>
        <h3 className='ScoreReviewModal__title'>{props.currentTurn.street.properties.name}</h3>
        <p className='ScoreReviewModal__copy'>
            Nice! You got within <strong>{props.currentTurn.guesses[props.currentTurn.guesses.length - 1].distance.toFixed(0)}m</strong>. You made <strong>{props.currentTurn.guesses.length}</strong> guess{props.currentTurn.guesses.length === 1 ? '' : 'es'}, which gets you <strong>{props.currentTurn.score.toLocaleString()}</strong> points.
        </p>
        <button className='ScoreReviewModal__button Button' onClick={props.handleNextTurnClick}>
            {props.currentTurn.score > 0 ? 'Sweet!' : 'OK'}
        </button>
    </div>
));

export default ScoreReviewModal;
