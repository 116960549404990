import React from 'react';

import './styles.scss';


const AboutModal = React.memo((props) => {
    return (
        <div className='AboutModalWrapper'>
            <div className='AboutModal'>
                <h1 className='AboutModal__title t-h1'>About Roadsage</h1>
                <p>
                    Roadsage is a toy written in React by <a href='https://www.biggleszx.com/' target='_blank' rel='noopener noreferrer'>BigglesZX</a>.
                </p>
                <h2 className='AboutModal__subtitle t-h2'>Source Data</h2>
                <p>
                    The street and postcode data used by Roadsage was prepared using a great deal of faffing in Jupyter notebooks.
                </p>
                <h2 className='AboutModal__subtitle t-h3'>Postcode district boundaries</h2>
                <p>
                    This data comes from Open Door Logistics who created a boundary data set based on unit postcode data as described <a href='https://www.opendoorlogistics.com/data/' target='_blank' rel='noopener noreferrer'>here</a>. Their <a href='https://www.opendoorlogistics.com/downloads/' target='_blank' rel='noopener noreferrer'>downloads page</a> links to TopoJSON versions of the Areas and Districts data created by Oliver Sinfield, and it is the Districts TopoJSON file that forms the basis for our data.
                </p>
                <p>
                    From there the Districts TopoJSON file was converted to the more verbose GeoJSON using <a href='https://mapshaper.org' target='_blank' rel='noopener noreferrer'>Mapshaper</a>. Uploading the TopoJSON source allows exporting to GeoJSON.
                </p>
                <p>
                    The GeoJSON data was then filtered to restrict our scope to only the central London postcodes (EC1-4/SE1/SW1/W1/WC1-2) and filtered again to combine the various sub-districts (e.g. W1U, W1B) into lists representing single districts (e.g. W1). These lists of GeoJSON features were then combined into single Polygon or MultiPolygon (for districts with distinct "exclave" areas) objects with Shapely. Lastly each combined Polygon or MultiPolygon was replaced with its `exterior` geometry to remove inner "enclave"-style holes.
                </p>
                <p>
                    The resulting GeoJSON files specify aggregate polygons for each of the top-level postal districts plus an overall "all" polygon, for use when searching OSM data and for use in the app UI. A simplified version of this overall polygon was created to use later as input to an Overpass query.
                </p>

                <h2 className='AboutModal__subtitle t-h3'>Street geometries</h2>
                <p>
                    Once the postal district boundary polygon data was obtained, the next task was to discover streets falling within those polygons. An Overpass API query was created to retrieve all named highways (a subset of OSM "way" objects) within the polygon coordinates. The resulting ~10MB of data was then processed to optimise it for our purposes.
                </p>
                <p>
                    The processing involved two main tasks. The first was to obtain a list of complete logical streets by grouping together way geometries by name, but taking care to correctly handle duplicate names (e.g. the three "King Street" instances in central London). A pass was made over the raw OSM way data and a dictionary of "approximate streets" created based on names. A second pass then reviewed the geometries of each approximate street and measured their distance from one another in order to create distinct geographical groupings sharing the same name.
                </p>
                <p>
                    Having obtained a list of logical streets, the second task was to determine which postcode districts covered each one. This involved a further iteration of the streets list, creating Shapely objects from each and comparing to the polygons of each postal district to check for intersection. Matching districts were then appended to each street data object.
                </p>
                <p>
                    The final step was to transform the data to GeoJSON, building a FeatureCollection of all streets with &gt;0 matching postal districts (which omitted outliers just outside the simplified central London polygon). The resulting simplified and optimised data set clocks in at around 1.2MB.
                </p>

                <h2 className='AboutModal__subtitle t-h2'>Credits</h2>
                <p>
                    This reconstructed dataset is copyright &copy; 2015 by OpenDoorLogistics (<a href='https://mapshaper.org' target='_blank' rel='noopener noreferrer'>www.opendoorlogistics.com</a>).
                </p>
                <p>
                    Contains Royal Mail data &copy; Royal Mail copyright and database right 2015<br />
                    Contains National Statistics data &copy; Crown copyright and database right 2015.
                </p>

                <button className='AboutModal__close Button' onClick={props.onCloseClick}>&times;</button>
            </div>
        </div>
    );
});

export default AboutModal;
