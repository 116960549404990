import flip from '@turf/flip';
import union from '@turf/union';
import L from 'leaflet';


const cardinalFromBearing = (bearing) => {
    // Convert a 0-360º bearing into the appropriate compass direction
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW', 'N'];
    const angle = 360 / 8;
    const offset = angle / 2;
    return directions[Math.floor((bearing + offset) / angle)];
};


const closestPointInFeature = (point, feature) => {
    // Find closest point in GeoJSON `Feature` to given point
    const featurePolyline = polylineFromFeature(feature);
    let distance = Number.MAX_SAFE_INTEGER;
    let closestPoint = null;
    featurePolyline.getLatLngs().forEach((latlngGroup) => {
        latlngGroup.forEach((latlng) => {
            let _distance = latlng.distanceTo(point);
            if (_distance < distance) {
                distance = _distance;
                closestPoint = latlng;
            }
        });
    });
    return closestPoint;
};

const makeFeatureCollection = (features) => ({
    type: 'FeatureCollection',
    features,
});

const polylineFromFeature = (feature) => {
    // Convert GeoJSON `Feature` into Leaflet `polyline`
    return L.polyline(flip(feature).geometry.coordinates);
};

const scoreGuesses = (guesses, distance) => {
    const scores = {
        1: 10000,
        2: 5000,
        3: 2500,
        4: 1250,
        5: 750,
    };
    const completionThreshold = 100;
    return [scores[guesses.length] || 0, distance <= completionThreshold];
};

const unionFromFeatureCollection = (featureCollection) => {
    // Make a union of polygons within a FeatureCollection, discarding all but
    // the first set of coordinates so as to remove enclaves
    const features = featureCollection.features;
    if (features.length === 1) return featureCollection;
    let combined = union(features[0], features[1]);
    if (features.length > 2) {
        for (let i = 2; i < features.length; i++) {
            combined = union(combined, features[i]);
        }
    }
    combined['geometry']['coordinates'] = combined['geometry']['coordinates'].slice(0, 1);
    return makeFeatureCollection([combined]);
};

export {
    cardinalFromBearing,
    closestPointInFeature,
    makeFeatureCollection,
    polylineFromFeature,
    scoreGuesses,
    unionFromFeatureCollection,
}
