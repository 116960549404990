import isRetina from 'is-retina';
import Konami from 'react-konami-code';
import L from 'leaflet';
import 'leaflet-geometryutil';
import sample from 'lodash.sample';
import PubSub from 'pubsub-js';
import React, { PureComponent } from 'react';
import { GeoJSON, Map, Marker, Polyline, ScaleControl, TileLayer } from 'react-leaflet';

import { closestPointInFeature, makeFeatureCollection, unionFromFeatureCollection } from '../../utils';

import './styles.scss';


// Adjust marker assets within leaflet so they play nice with webpack
// source: https://github.com/PaulLeCam/react-leaflet/issues/255#issuecomment-388492108
import 'leaflet/dist/leaflet.css';
import marker from 'leaflet/dist/images/marker-icon.png';
import marker2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: marker2x,
    iconUrl: marker,
    shadowUrl: markerShadow
});


class StreetMap extends PureComponent {
    constructor (props) {
        super(props);

        // Basic Leaflet map properties
        this.tileLayerId = 'biggleszx/ck09q27m6258e1cohw6ievdbq';
        this.tileLayerUrl = `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}${isRetina() ? '@2x' : ''}?access_token={accessToken}`;
        this.attribution = 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>';
        this.accessToken = 'pk.eyJ1IjoiYmlnZ2xlc3p4IiwiYSI6ImNqenhiYWF3bDBqeGIzYm8xd3A4NnE3MjgifQ.SXU-VfXpfhEVkfLhxs5UCA';

        // Initial map configuration
        this.initialCenter = [51.509865, -0.118092];
        this.initialZoom = 12;
        this.minZoom = 12;
        this.maxZoom = 16;
        this.defaultBounds = null;

        // Leaflet layer styles
        this.styles = {
            line: { color: '#ff0000', weight: 2 },
            hiddenLine: { color: '#ff0000', weight: 0 },
            dottedLine: { color: '#0000ff', weight: 2, dashArray: '5,5' },
            shape: { color: '#ff0000', weight: 0, fillOpacity: 0.2, fillColor: '#ff0000' },
            shapeAlt: { color: '#ff0000', weight: 2, dashArray: '5,5', fillOpacity: 0.1, fillColor: '#ff0000' },
        };

        // Initial state
        this.state = {
            attractorFeatures: [],
            konami: false,
        };
    }

    componentDidMount () {
        // Subscribe to pubsub channel to listen for recenter messages
        this.pubSubToken = PubSub.subscribe('RECENTER', this.onPubSubRecenter);

        // Save a reference to the Leaflet map instance within the Map component
        this.leafletMapElement = this.refs.map.leafletElement;
    }

    componentDidUpdate (prevProps) {
        // When district data is loaded and provided via props, get the bounds
        // of the district map layer and save them to be used later for
        // resetting the viewport.
        // If the selectedDistrictGroup has changed, reset attractorFeatures
        if ((this.props.districtData && !this.defaultBounds)
            || (this.defaultBounds && this.props.selectedDistrictGroup !== prevProps.selectedDistrictGroup)) {
            this.setState({
                attractorFeatures: [],
            });
            const districtsLayer = this.refs.districtsLayer;
            if (districtsLayer) {
                this.defaultBounds = districtsLayer.leafletElement.getBounds();
                this.reCenter();
            }
        }

        // When current turn is marked as completed, determine bounds of current
        // street and position marker, and fly to those bounds.
        // If new turn, re-center the map
        if (this.props.currentTurn && this.props.currentTurn.completed && prevProps.currentTurn && !prevProps.currentTurn.completed) {
            const street = this.refs.currentStreetLayer;
            const marker = this.refs.marker;
            if (street && marker) {
                const bounds = street.leafletElement.getBounds().pad(0.2)
                                     .extend(marker.leafletElement.getLatLng());
                this.leafletMapElement.flyToBounds(bounds);
            }
        } else if (this.props.currentTurn && !this.props.currentTurn.completed && prevProps.currentTurn && prevProps.currentTurn.completed) {
            // New turn
            this.reCenter();
        }

        // If the welcome route is active, tick attractor mode
        if (this.props.streetData && this.props.location.pathname === '/welcome') {
            window.requestAnimationFrame(this.rotateAttractorFeatures);
        }

        // If we're at the root or welcome routes, remove zoom control
        if (['/', '/welcome'].includes(this.props.location.pathname)) {
            this.leafletMapElement.zoomControl.remove();
        } else {
            this.leafletMapElement.zoomControl.addTo(this.leafletMapElement);
        }
    }

    componentWillUnmount () {
        // Clean up pubsub when unmounting
        PubSub.unsubscribe(this.pubSubToken);
    }

    enableKonami = () => {
        this.setState({
            konami: true,
        });
    }

    getDottedLineStyle = () => {
        return this.styles.dottedLine;
    }

    getHiddenLineStyle = () => {
        return this.styles.hiddenLine;
    }

    getLineStyle = () => {
        return this.styles.line;
    }

    getShapeStyle = () => {
        return this.styles.shape;
    }

    getShapeStyleAlt = () => {
        return this.styles.shapeAlt;
    }

    handleMapClick = (event) => {
        // Update the current position
        if (this.props.currentTurn) {
            this.props.handlePositionChange(event.latlng);
        }
    }

    handleMarkerDragEnd = () => {
        // Update the current position
        const marker = this.refs.marker;
        if (marker) {
            this.props.handlePositionChange(marker.leafletElement.getLatLng());
        }
    }

    onPubSubRecenter = () => {
        // On recenter event from pubsub, recenter
        this.reCenter();
    }

    reCenter = () => {
        // Reset the viewport to the bounds of the district layer
        this.leafletMapElement.flyToBounds(this.defaultBounds);
    }

    rotateAttractorFeatures = () => {
        // Add a new street to the attractor set and discard an old one if
        // necessary
        const attractorFeatures = this.state.attractorFeatures.concat([sample(this.props.streetData.features)]);
        if (attractorFeatures.length > 100) attractorFeatures.shift();
        this.setState({
            attractorFeatures,
        });
    }

    render () {
        const renderStreet = (visible) => {
            return (
                <GeoJSON
                    key={this.props.currentTurn.street.properties.name}
                    data={makeFeatureCollection([this.props.currentTurn.street])}
                    style={visible ? this.getLineStyle : this.getHiddenLineStyle}
                    ref='currentStreetLayer'
                />
            );
        };

        return (
            <Map
                ref='map'
                className='StreetMap'
                useFlyTo={true}
                minZoom={this.minZoom}
                maxZoom={this.maxZoom}
                onClick={this.handleMapClick}
                center={this.initialCenter}
                zoom={this.initialZoom}>

                <ScaleControl />

                <TileLayer
                    attribution={this.attribution}
                    url={this.tileLayerUrl}
                    accessToken={this.accessToken}
                    id={this.tileLayerId}
                />

                {this.props.districtData && (
                    <GeoJSON
                        ref='districtsLayer'
                        key={this.props.selectedDistrictGroup}
                        data={unionFromFeatureCollection(this.props.districtData)}
                        style={this.props.currentTurn ? this.getShapeStyleAlt : this.getShapeStyle}
                    />
                )}

                {this.props.location.pathname === '/welcome' && this.props.streetData && this.state.attractorFeatures.length > 0 && (
                    <GeoJSON
                        key={this.state.attractorFeatures[0].properties.name}
                        data={makeFeatureCollection(this.state.attractorFeatures)}
                        style={this.getLineStyle}
                    />
                )}

                {this.props.currentTurn && this.props.currentTurn.street && renderStreet(this.props.currentTurn.completed || this.state.konami)}

                {this.props.currentTurn && this.props.currentTurn.guesses.length > 0 && (
                    <Marker
                      draggable={!this.props.currentTurn.completed}
                      onDragend={this.handleMarkerDragEnd}
                      position={this.props.currentTurn.guesses[this.props.currentTurn.guesses.length - 1].position}
                      ref='marker'
                    />
                )}

                {this.props.currentTurn && this.props.currentTurn.completed && (
                    <Polyline positions={[this.props.currentTurn.guesses[this.props.currentTurn.guesses.length - 1].position, closestPointInFeature(this.props.currentTurn.guesses[this.props.currentTurn.guesses.length - 1].position, this.props.currentTurn.street)]} {...this.styles.dottedLine} />
                )}

                <Konami action={this.enableKonami} />
            </Map>
        );
    }
}

export default StreetMap;
