import React from 'react';

import './styles.scss';


const Scoreboard = React.memo((props) => {
    const turns = props.turnHistory.length;
    const score = props.turnHistory.length ? props.turnHistory.reduce((a, b) => a + b.score, 0) : 0;
    return (
        <div className='Scoreboard'>
            <p className='Scoreboard__meta'>
                <strong className='Scoreboard__title t-title'>roadsage</strong> by <a href='https://www.biggleszx.com/' target='_blank' rel='noopener noreferrer'>BigglesZX</a> &nbsp;&middot; &nbsp;
                <button className='Scoreboard__about-button' onClick={props.onAboutClick}>About + Credits</button>
            </p>
            <p className='Scoreboard__score'>
                <strong>{score.toLocaleString()} points</strong> from {turns} turn{turns === 1 ? '' : 's'}
            </p>
        </div>
    );
});

export default Scoreboard;
