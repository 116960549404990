import React from 'react';

import './styles.scss';


const Loading = React.memo((props) => (
    <div className='Loading'>
        <progress className='Loading__progress' max='100' value={props.progress}>
            {props.progress || 0}%
        </progress>
    </div>
));

export default Loading;
