import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';

import fetchProgress from 'fetch-progress';
import L from 'leaflet';
import filter from 'lodash.filter';
import get from 'lodash.get';
import sample from 'lodash.sample';
import PubSub from 'pubsub-js';
import React, { PureComponent } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import AboutModal from './components/AboutModal';
import Loading from './components/Loading';
import PlayControls from './components/PlayControls';
import Scoreboard from './components/Scoreboard';
import ScoreReviewModal from './components/ScoreReviewModal';
import StreetMap from './components/StreetMap';
import WelcomeModal from './components/WelcomeModal';

import { closestPointInFeature, scoreGuesses } from './utils';

import './App.scss';


class App extends PureComponent {
    constructor (props) {
        super(props);

        // Define fetchProgress handler for updating loading progress state
        this.handleLoadingProgress = fetchProgress({
            onProgress: (progress) => {
                this.setState({
                    loadingProgress: progress.percentage,
                });
            },
        });

        // District groups
        const availableDistrictGroups = {
            'All': ['ALL'],
            'W1': ['W1'],
            'WC': ['WC1', 'WC2'],
            'EC': ['EC1', 'EC2', 'EC3', 'EC4'],
            'SE1': ['SE1'],
            'SW1': ['SW1'],
        };

        // Initial state
        this.state = {
            loadingProgress: null,

            allDistrictData: null,
            districtData: null,
            availableDistrictGroups,
            selectedDistrictGroup: 'All',

            allStreetData: null,
            streetData: null,

            turnHistory: [],
            currentTurn: null,

            aboutModalVisible: false,
        };
    }

    addGuess (position) {
        const closestPoint = closestPointInFeature(position, this.state.currentTurn.street);
        const distance = position.distanceTo(closestPoint);
        let bearing = L.GeometryUtil.bearing(closestPoint, position);
        if (bearing < 0) bearing += 360;

        const guesses = [
            ...this.state.currentTurn.guesses,
            {
                position,
                bearing,
                distance,
            }
        ];
        const [score, completed] = scoreGuesses(guesses, distance);

        this.setState({
            currentTurn: {
                ...this.state.currentTurn,
                guesses,
                score,
                completed,
            },
        });
    }

    componentDidMount () {
        // Bootstap the application by loading the initial district data
        this.loadDistrictData();
    }

    componentDidUpdate (prevProps) {
        // Reset turn state if (re)entering the welcome route
        if (get(prevProps, 'location.pathname') !== '/welcome' && this.props.location.pathname === '/welcome') {
            this.setState({
                currentTurn: null,
            });
        }

        // Advance to the next turn if we're on the `play` route and there's no
        // turn currently active
        if (this.props.location.pathname === '/play' && !this.state.currentTurn) {
            this.nextTurn();
        }
    }

    handleAboutClick = () => {
        this.setState({
            aboutModalVisible: true,
        });
    }

    handleAboutCloseClick = () => {
        this.setState({
            aboutModalVisible: false,
        });
    }

    handleDistrictGroupSelect = (dg) => {
        // Update selected district and thus the current subset of district and
        // street data
        const selectedDistrictGroup = this.state.availableDistrictGroups[dg];
        this.setState({
            selectedDistrictGroup: dg,
            districtData: {
                ...this.state.allDistrictData,
                features: filter(
                    this.state.allDistrictData.features,
                    d => selectedDistrictGroup.includes(d.properties.name)
                ),
            },
            streetData: {
                ...this.state.allStreetData,
                features: dg === 'All' ? this.state.allStreetData.features : filter(
                    this.state.allStreetData.features,
                    s => s.properties.districts.some(d => selectedDistrictGroup.includes(d))
                ),
            },
            turnHistory: [],
            currentTurn: null,
        });
    }

    handleNextTurnClick = () => {
        // Advance to the next turn
        this.nextTurn();
    }

    handlePositionChange = (position) => {
        // Add the position guess to the current turn object (if not already
        // completed)
        if (!this.state.currentTurn.completed) {
            this.addGuess(position);
        }
    }

    handleReCenterClick = () => {
        // Publish recenter pubsub message for map component
        PubSub.publish('RECENTER');
    }

    loadDistrictData () {
        // Fetch district data from JSON, update state and request fetch of
        // street data
        fetch('data/districts.json')
            .then(this.handleLoadingProgress)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    allDistrictData: data,
                    districtData: {
                        ...data,
                        features: filter(
                            data.features,
                            f => this.state.availableDistrictGroups['All'].includes(f.properties.name)
                        ),
                    },
                });
                this.loadStreetsData();
            });
    }

    loadStreetsData () {
        // Fetch street data from JSON, update state
        fetch('data/streets.json')
            .then(this.handleLoadingProgress)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    allStreetData: data,
                    streetData: data,
                });
                this.props.history.push('/welcome');
            });
    }

    nextTurn () {
        // Add the current turn to the turn history array, and reset state with
        // a new turn object
        if (this.state.currentTurn) {
            this.setState({
                turnHistory: [...this.state.turnHistory, this.state.currentTurn],
            });
        }
        this.setState({
            currentTurn: {
                street: sample(this.state.streetData.features),
                completed: false,
                score: null,
                guesses: [],
            },
        });
    }

    render () {
        if (!(this.state.districtData && this.state.streetData)) return <Redirect to='/' />
        return (
            <div className='App'>
                <Route render={routeProps => (
                    <StreetMap
                        districtData={this.state.districtData}
                        streetData={this.state.streetData}
                        selectedDistrictGroup={this.state.selectedDistrictGroup}
                        currentTurn={this.state.currentTurn}
                        handlePositionChange={this.handlePositionChange}
                        {...routeProps}
                    />
                )} />
                <Switch>
                    <Route path='/' exact render={routeProps => (
                        <Loading progress={this.state.loadingProgress} />
                    )} />
                    <Route path='/welcome' render={routeProps => (
                        <WelcomeModal
                            linkTo='/play'
                            availableDistrictGroups={this.state.availableDistrictGroups}
                            selectedDistrictGroup={this.state.selectedDistrictGroup}
                            onDistrictGroupSelect={this.handleDistrictGroupSelect}
                        />
                    )} />
                    <Route path='/play' render={routeProps => (
                        <>
                            <Scoreboard
                                turnHistory={this.state.turnHistory}
                                onAboutClick={this.handleAboutClick}
                            />
                            {this.state.currentTurn && (
                                <PlayControls
                                    currentTurn={this.state.currentTurn}
                                    onReCenterClick={this.handleReCenterClick}
                                    restartLinkTo={'/welcome'}
                                />
                            )}
                            {get(this.state, 'currentTurn.completed') && (
                                <ScoreReviewModal
                                    currentTurn={this.state.currentTurn}
                                    handleNextTurnClick={this.handleNextTurnClick}
                                />
                            )}
                        </>
                    )} />
                </Switch>
                {this.state.aboutModalVisible && (
                    <AboutModal onCloseClick={this.handleAboutCloseClick} />
                )}
            </div>
        );
    }
}

export default withRouter(App);
