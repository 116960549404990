import React from 'react';

import LinkButton from '../LinkButton';

import { cardinalFromBearing } from '../../utils';

import './styles.scss';


const PlayControls = React.memo((props) => (
    <div className='PlayControls'>
        <p className='PlayControls__buttons'>
            <button className='Button' onClick={props.onReCenterClick}>Re-Center</button>
            <LinkButton className='Button' to={props.restartLinkTo}>Restart</LinkButton>
        </p>
        <p className='PlayControls__turn-info'>
            Get within 100m of <strong>{props.currentTurn.street.properties.name}</strong> ({props.currentTurn.street.properties.districts.join('/')})
        </p>
        {props.currentTurn.guesses.length > 0 && (
            <p className='PlayControls__guess-info'>
                Last guess was <strong>{props.currentTurn.guesses[props.currentTurn.guesses.length - 1].distance.toFixed(0)}m</strong> away in a  <strong>{cardinalFromBearing(props.currentTurn.guesses[props.currentTurn.guesses.length - 1].bearing)}</strong> direction. You have made <strong>{props.currentTurn.guesses.length}</strong> {props.currentTurn.guesses.length === 1 ? 'guess' : 'guesses'}.
            </p>
        )}
    </div>
));

export default PlayControls;
